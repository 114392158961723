import React from 'react'
import { Container, Hero } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'

function TechHero() {
  return (
    <Hero className="is-small is-dark">
      <Hero.Body>
        <Container className="content">
          <div className="columns is-tablet is-vcentered">
            <div className="column is-6 is-offset-1">
              <h1 className="title">
                The <span className="has-text-info">tech</span> we use to
                deliver awesome apps and web platforms
              </h1>
              <p className="is-size-4">
                For all you CTO's, CDO's and technical folks... We're a
                full-stack digital team who focus mostly on software platforms
                where mobile is hugely important to the success of the business.
              </p>
            </div>
            <div className="column is-offset-0 is-5">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1580922345-bb8.png?w=450"
                alt="Pocketworks Tech"
                placeholder="tracedSVG"
              />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default TechHero
