import React from 'react'

// Components
import TeamQuote from 'components/team-quote'

function TechQuote() {
  return (
    <TeamQuote
      quote="There's a lot of cool tech out there, it's easy to get sucked in. The important thing is keeping it simple and picking the right tools for the job."
      imgSrc="https://www.datocms-assets.com/20955/1579120423-matt.jpg?fm=png&w=300&fit=facearea&facepad=2.5&mask=ellipse&duotone=0000C5,72B9FA&duotone-alpha=100"
      who="Matej, Full Stack Developer"
    />
  )
}

export default TechQuote
