import PropTypes from 'prop-types'
import React from 'react'
import { Hero, Columns, Container } from 'react-bulma-components'

function TeamQuote({ nobTopBorder, noBottomBorder, quote, who, imgSrc }) {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          {nobTopBorder ? <span className="is-hidden" /> : <hr />}
          <Columns className="is-inline-flex is-centered">
            <Columns.Column className="is-2 ">
              <img src={imgSrc} alt="Test" />
            </Columns.Column>
            <Columns.Column className="is-6 is-vcentered">
              <p className="is-size-4">"{quote}"</p>
              <p className="tag is-medium">{who}</p>
            </Columns.Column>
          </Columns>
          {noBottomBorder ? <span className="is-hidden" /> : <hr />}
        </Container>
      </Hero.Body>
    </Hero>
  )
}

TeamQuote.propTypes = {
  nobTopBorder: PropTypes.bool,
  noBottomBorder: PropTypes.bool,
  imgSrc: PropTypes.string,
  quote: PropTypes.string,
  who: PropTypes.string,
}

export default TeamQuote
