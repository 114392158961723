import React from 'react'
import { Hero } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import SiteLinks from './components/site-links'
import TechHero from './components/tech-hero'
import TechUsed from './components/tech-used'
import TechFrameworks from './components/tech-frameworks'
import TechQuote from './components/tech-quote'
import TechPractices from './components/tech-practices'

function Technologies() {
  return (
    <Layout>
      <div className="page is-dark">
        <Hero className="is-small is-dark">
          <SiteNav className="is-light" fullLogo />
          <SiteLinks activePage="tech" />
        </Hero>
        <TechHero />
        <TechUsed />
        <TechFrameworks />
        <TechQuote />
        <TechPractices />
      </div>
    </Layout>
  )
}

export default Technologies
