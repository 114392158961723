import React from 'react'
import { Link } from 'gatsby'
import { Columns, Container, Hero } from 'react-bulma-components'

function TechFrameworks() {
  return (
    <Hero className="">
      <Hero.Body>
        <Container className="content has-text-light">
          <Columns className="is-centered">
            <Columns.Column className="is-10 has-text-centered">
              <br />
              <h2 className="">
                Dev tools and <span className="has-text-info">frameworks</span>
              </h2>
              <br />
            </Columns.Column>
            <Columns.Column className="is-9 is-hidden">
              <h3 className="">
                Google Flutter <span className="has-text-danger">(new!)</span>
              </h3>
              <p>
                If you need to reach an audience on both Android and iOS,
                Flutter is worth evaluating. Since 2012 we have always used
                Objective-C, <a href="https://swift.org">Swift</a>, Kotlin and
                Java. However, in 2020 we finally felt that Flutter was good
                enough for professional use. It's not perfect for all
                situations, so feel free to ask us if it's a good fit for your
                project.
              </p>
            </Columns.Column>
          </Columns>
          <Columns className="is-centered">
            <Columns.Column className="is-3">
              <h3>Native Mobile Apps</h3>
              <p>
                When developing on iOS, we tend to choose{' '}
                <a href="https://swift.org">Swift</a> for new apps. On Android,
                we choose <a href="https://kotlinlang.org/">Kotlin</a>. Since
                2020, we started developing{' '}
                <a href="https://flutter.dev">Flutter</a> apps professionally.{' '}
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Web Portals</h3>
              <p>
                For simple front-end web interfaces, we'll work with vanilla
                HTML5. We'll use Vuejs, Nuxt or React for more sophisticated
                solutions. We use Elixir and Phoenix for most of our business
                logic.
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Progressive Web Apps</h3>
              <p>
                For PWAs that need an app-like experience, we use ReactJS or
                VueJS. The back-end can be Elixir and Phoenix. We also have some
                Ruby on Rails solutions.{' '}
              </p>
            </Columns.Column>
          </Columns>

          <Columns className="is-centered">
            <Columns.Column className="is-3">
              <h3>Integrations</h3>
              <p>
                We tend to use RESTful API's that exchange JSON. If needed, with
                some appropriate caching policies. Occasionally we need to use
                XML and SOAP for certain integrations. We'll use a message
                brokers for high volume transactions. MQTT for IoT scenarios.{' '}
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Continuous Integration</h3>
              <p>
                Most of apps build and deploy using Circle CI. For web apps
                hosted on Heroku, we'll use their build pipeline. This website
                is on Netlify and uses it's own build pipeline. CI usually runs
                tests and can deploy to staging or live.{' '}
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Databases</h3>
              <p>
                Postgres is our current go-to SQL DB, scaled vertically with a
                replica for read-heavy use cases. For integrating web sites to
                apps, we like RESTful API's with appropriate caching mechanisms,
                and are also exploring GraphQL.
              </p>
            </Columns.Column>
          </Columns>

          <Columns>
            <Columns.Column className="is-offset-2 is-8 has-text-centered">
              <p>
                <br />
                <Link
                  to="/"
                  className="button is-info is-outlined is-leafy is-medium"
                >
                  Want to talk tech?
                </Link>
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default TechFrameworks
