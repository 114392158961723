import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

function SiteLinks({ activePage }) {
  return (
    <div className="tabs is-medium is-centered">
      <ul>
        <li className={activePage === 'story' ? 'is-active' : ''}>
          <Link to="/about/story">Story</Link>
        </li>
        <li className={activePage === 'pocketeers' ? 'is-active' : ''}>
          <Link to="/about/pocketeers">Pocketeers</Link>
        </li>
        <li className={activePage === 'join' ? 'is-active' : ''}>
          <Link to="/about/careers">Join Us</Link>
        </li>
        <li className={activePage === 'tech' ? 'is-active' : ''}>
          <Link to="/about/technologies">The Tech</Link>
        </li>
      </ul>
    </div>
  )
}

SiteLinks.propTypes = {
  activePage: PropTypes.string,
}

export default SiteLinks
