import React from 'react'
import { Link } from 'gatsby'
import { Columns, Container, Hero } from 'react-bulma-components'

function TechPractices() {
  return (
    <Hero className="">
      <Hero.Body>
        <Container className="content has-text-light">
          <Columns className="is-centered">
            <Columns.Column className="is-10 has-text-centered">
              <h2 className="">
                Philosophy and <span className="has-text-info">practices</span>
              </h2>
              <br />
              <br />
            </Columns.Column>
          </Columns>
          <Columns className="is-centered">
            <Columns.Column className="is-3">
              <h3>Design Thinking</h3>
              <p>
                When creating any digital product, we use a combination of
                design-thinking and agile delivery. This is because we believe
                these globally accepted best practices give you the best shot of
                creating a product that actually gets results.
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Agile</h3>
              <p>
                Unlike many software houses, we strive for the core principles
                of agile set out in the agile manifesto, which encourages
                collaboration, flexible project planning and doing the simplest
                thing that works.
              </p>
            </Columns.Column>
            <Columns.Column className="is-3">
              <h3>Data Informed</h3>
              <p>
                We think it's important to measure things, and use what you
                learn to feed back into the product lifecycle. We use
                AplitudeHQ, Google Analytics and other frameworks for observing
                user behaviour.
              </p>
            </Columns.Column>
          </Columns>

          <Columns>
            <Columns.Column className="is-offset-2 is-8 has-text-centered">
              <p>
                <br />
                <Link
                  to="/"
                  className="button is-info is-outlined is-leafy is-medium"
                >
                  Want to talk tech?
                </Link>
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default TechPractices
