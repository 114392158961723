import React from 'react'
import { Container, Hero } from 'react-bulma-components'

function TechUsed() {
  return (
    <>
      <Hero className="is-medium has-background-black-ter is-hidden-mobile">
        <Hero.Body>
          <Container className="has-text-light has-text-centered">
            <div className="level">
              <span className="level-item">
                <i className="fab fa-android fa-3x" />
              </span>
              <span className="level-item">
                <i className="fab fa-apple fa-3x" />
              </span>
              <span className="level-item">
                <i className="fab fa-swift fa-3x" />
              </span>
              <span className="level-item">
                <i className="fab fa-react fa-3x" />
              </span>
              <span className="level-item">
                <i className="fab fa-phoenix-framework fa-3x" />
              </span>

              <span className="level-item">
                <i className="fas fa-database fa-3x" />
              </span>
              <span className="level-item">
                <i className="fab fa-figma fa-3x" />
              </span>
              <span className="level-item">
                <i className="fab fa-sketch fa-3x" />
              </span>
              <span className="level-item">
                <i className="fab fa-html5 fa-3x" />
              </span>
            </div>
          </Container>
        </Hero.Body>
      </Hero>
      <Hero className="is-small has-background-black-ter is-hidden-tablet is-mobile-only">
        <Hero.Body>
          <Container className="has-text-light has-text-centered">
            <div className="level is-mobile">
              <span className="level-item">
                <i className="fab fa-android fa-2x" />
              </span>
              <span className="level-item">
                <i className="fab fa-swift fa-2x" />
              </span>
              <span className="level-item">
                <i className="fab fa-react fa-2x" />
              </span>
              <span className="level-item">
                <i className="fab fa-phoenix-framework fa-2x" />
              </span>
              <span className="level-item">
                <i className="fab fa-figma fa-2x" />
              </span>
              <span className="level-item">
                <i className="fab fa-html5 fa-2x" />
              </span>
            </div>
          </Container>
        </Hero.Body>
      </Hero>
    </>
  )
}

export default TechUsed
